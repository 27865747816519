<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container-fluid">
          <div class="card card-shape home-box">
            <div class="card-header py-3">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">Income Statement Reports</h6>
                </div>
                <div class="col-6 text-end">
                  <b-button @click="generateExcel" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']" />
                  </b-button>
                  <report-filter :title="'INCOME STATEMENT'" :form="form" @filterAction="filterAction"
                    :filter="filter"></report-filter>
                  <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"
                    title="Print"><font-awesome-icon :icon="['fas', 'book']" /></b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"
                    title="Download"><font-awesome-icon :icon="['fas', 'download']" /></b-button>
                </div>
              </div>
            </div>
            <div class="card-body" ref="pdfContent">
              <div class="row">
                <div class="col-12 col-md-12">
                  <statement-institution :institution="institution" :current_date="false"></statement-institution>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-4 text-end">
                  <span style="font-weight: bold" v-if="start_date && end_date">From {{ start_date }} to {{ end_date
                    }}</span>
                </div>
              </div>
              <div class="" style="text-align: center;">
                <div class="fw-bold akkurate-green">NOTE TO INCOME STATEMENT</div>
              </div>
              <div v-if="isBusy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
              <div v-else id="balance-sheet" class="row">
                <div class="col-12 my-2">
                </div>
                <div class="col-6 col-md-6 px-0 trans_details">
                  <b-table-simple responsive outlined bordered>
                    <b-thead>
                      <b-tr>
                        <b-th class="text-center fs-6 akkurate-green" colspan="3"><span>INCOMES</span></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(value, item) in incomes" :key="item" class="list-item">
                        <b-td style="font-weight: bold;">{{ value.display_name }}</b-td>

                        <b-td style="font-weight: bold;" v-if="value.transactions.length > 0">
                          GHS {{ Number(value.transactions[0].balance ? value.transactions[0].balance.toFixed(2) :
                            0).toLocaleString() }}
                        </b-td>
                        <b-td style="font-weight: bold;" v-else>
                          GHS 0
                        </b-td>

                        <b-td class="list-item">
                          <div v-for="(val, idx) in value.subheads" :key="idx" class="row">
                            <div class="col-6">{{ val.name }}</div>
                            <div class="col-6"> GHS {{ Number(val.total ? val.total.toFixed(2) : 0).toLocaleString() }}
                            </div>
                            <hr v-if="idx < value.subheads.length - 1" style="border-color: #808080;" class="m-0 ms-1" />
                          </div>

                        </b-td>
                      </b-tr>
                    </b-tbody>
                    <!-- <b-tfoot>
                            <b-tr>
                              <b-th>Total Assets</b-th>
                            </b-tr>
                          </b-tfoot> -->
                  </b-table-simple>
                </div>
                <div class="col-6 col-md-6 px-0 trans_details">
                  <b-table-simple responsive outlined bordered>
                    <b-thead>
                      <b-tr>
                        <b-th class="text-center fs-6 akkurate-green" colspan="3"><span>EXPENSES</span></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(value, item) in expenses" :key="item" class="list-item">
                        <b-td style="font-weight: bold;">{{ value.display_name }}</b-td>
                        <b-td style="font-weight: bold;" v-if="value.transactions.length > 0">
                          GHS {{ Number(value.transactions[0].balance ? value.transactions[0].balance.toFixed(2) :
                            0).toLocaleString() }}

                        </b-td>
                        <b-td v-else>
                          GHS 0
                        </b-td>
                        <b-td>
                          <div class="row" v-for="(val, idx) in value.subheads" :key="idx">
                            <div class="col-6">
                              {{ val.name }}
                            </div>
                            <div class="col-6">
                              GHS {{ val.total }}
                            </div>
                            <hr v-if="idx < value.subheads.length - 1" style="border-color: #808080;" class="m-0 ms-1" />

                          </div>
                          
                        </b-td>

                      </b-tr>
                    </b-tbody>
                    <!-- <b-tfoot>
                            <b-tr>
                              <b-th>Total Assets</b-th>
                              <b-th>{{ sumAssetsTotal }}</b-th>
                            </b-tr>
                          </b-tfoot> -->
                  </b-table-simple>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-6 p-0 trans_details">
                  <b-table-simple responsive outlined bordered>

                    <b-tbody>
                      <b-tr>
                        <b-th class="py-0 px-2">Total Incomes (GHS)</b-th>
                        <b-th class="py-0 px-2">{{ Number(grant_total_incomes ? grant_total_incomes.toFixed(2) : 0).toLocaleString()
                          }}</b-th>
                      </b-tr>
                    </b-tbody>

                    <b-tfoot>
                      <b-tr>
                        <b-th class="py-0 px-2" v-if="profit_or_loss < 0">Loss (GHS)</b-th>
                        <b-th class="py-0 px-2" v-else>Profit (GHS)</b-th>
                        <b-th class="py-0 px-2">{{ Number(profit_or_loss ? Math.abs(profit_or_loss).toFixed(2) : 0).toLocaleString()
                          }}</b-th>
                      </b-tr>
                    </b-tfoot>
                  </b-table-simple>
                </div>
                <div class="col-6 col-md-6 p-0 trans_details">
                  <b-table-simple responsive outlined bordered>

                    <b-tbody>
                      <b-tr>
                        <b-th class="py-0 px-2">Total Expenses (GHS)</b-th>
                        <b-th class="py-0 px-2">{{ Number(grant_total_expenses ? grant_total_expenses.toFixed(2) : 0).toLocaleString()
                          }}</b-th>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import html2pdf from "html2pdf.js";
import ReportFilter from "../form/ReportFilter.vue";
import ExcelJS from 'exceljs';
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter
  },
  data() {
    return {
      form: {
        start_date: this.getBeginningOfYear(),
        end_date: new Date().toISOString().substr(0, 10),
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        coa: [],
        coa_options: [],
        terms: "Periodic",
        terms_options: ["YTD", "Periodic"],
        categories: [],
      },
      filter: {
        branches: false,
        products: false,
        users: false,
        status: false,
        condition: false,
        terms: true,
        coa: false,
        voucher_status: false
      },
      incomes: [],
      expenses: [],
      profit_or_loss: 0,
      search: "Search",
      grant_total_expenses: 0,
      grant_total_incomes: 0,
      isBusy: false,
      isSearching: false,
      selected_num: "10",
      numbers_options: [10, 50, 100, 500],
      isLoadingInstitution: false,
      institution: "",
      start_date: "",
      end_date: "",
      fields: [
        {
          key: "account_number",
          label: "Account Number",
        },
        {
          key: "customer_name",
          label: "Customer Name",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "min_balance",
          label: "Minimum Balance",
        },
        {
          key: "total",
          label: "Total",
        },

      ],
      perPage: 20,
      currentPage: 1,
      items: [],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    sumLoanBalance() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = this.items[i].loan_balance;
      }
      return total;
    },
    sumSavingBalance() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = this.items[i].saving_balance;
      }

      return total;
    },
    //assets total
    //   sumAssetsTotal(){
    //     let total = 0;
    //     for(let i = 0; i < this.incomes.length; i++){
    //       total = this.incomes[i].amount;
    //     }
    //     return total;
    //   },
  },

  created() {
    this.getInstitution();
    this.getBalanceSheets();
  },
  methods: {
    async getBalanceSheets() {
      this.items = [];
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.query("/reports/financials/income-statement", {
        params: {
          startDate: this.form.start_date,
          endDate: this.form.end_date,
          terms: this.form.terms
        },
      })
        .then((response) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          // var res = response.data?.data;
          var res = response.data;
          this.incomes = res.incomes;
          this.expenses = res.expenses;
          this.grant_total_incomes = res.grant_total_incomes;
          this.grant_total_expenses = res.grant_total_expenses;
          this.profit_or_loss = this.grant_total_incomes - this.grant_total_expenses;
          this.start_date = this.form.start_date;
          this.end_date = this.form.end_date;
        })
        .catch((error) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          console.log(error);
        });
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    filterAction() {
      this.getBalanceSheets();
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    async generateExcel() {
      // const fileUrl = '/REPORT_TEMPLATE.xlsx'; // Path to the file in the public folder

      // try {
      //   // Fetch the Excel file from the public URL
      //   const response = await fetch(fileUrl);
      //   if (!response.ok) {
      //     throw new Error(`Network response was not ok: ${response.statusText}`);
      //   }
      //   const arrayBuffer = await response.arrayBuffer();

      //   // Load the file into ExcelJS
      //   const wb = new ExcelJS.Workbook();
      //   await wb.xlsx.load(arrayBuffer);

      //   // Access the first sheet
      //   const ws = wb.getWorksheet(1); // Get the first worksheet

      //   // Define header row
      //   const header = [
      //     "fullname",
      //     "customer external code",
      //     "customer code",
      //     "transaction date",
      //     "transaction type",
      //     "account type name",
      //     "transaction amount",
      //     "code",
      //     "affected_coa",
      //   ];

      //   // Add header row starting from row 13
      //   const startingRow = 13;
      //   let headerRow = ws.getRow(startingRow);
      //   headerRow.values = header;
      //   headerRow.font = { bold: true };
      //   headerRow.commit(); // Commit the row to the worksheet

      //   // Add individual data rows
      //   let currentRow = startingRow + 1;

      //   // Process each item
      //   this.incomes.forEach(item => {
      //     const row = ws.getRow(currentRow);
      //     // Map item values to the corresponding header columns
      //     row.values = [
      //       item.customer?.first_name + ' ' + item.customer?.last_name,
      //       item.customer.external_code, // Remove extra spaces
      //       item.customer.code,
      //       item.trans_date,
      //       item.transaction_type,
      //       item.account.account_type?.name,
      //       item.trans_amount,
      //       item.code,
      //       item.affected_coa,
      //     ];
      //     row.commit();
      //     currentRow++;
      //   });
      //   const buffer = await wb.xlsx.writeBuffer();
      //   const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      //   // Create a download link and simulate a click to download the file
      //   const link = document.createElement('a');
      //   link.href = URL.createObjectURL(blob);
      //   link.download = 'Modified_REPORT_TEMPLATE.xlsx';
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      // } catch (err) {
      //   console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
      // }
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
  },
};
</script>