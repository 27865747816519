<template>
    <div>
        <!-- <a title="View Documents" class="btn btn-sm akkurate-green-btn text-white"  v-b-modal="String(data.id+'document')">
            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'book']" /></span>
        </a> -->
        <a class="btn btn-sm akkurate-green-btn" v-b-modal="'loan-form'" variant="primary">
            <span class="akkurate-small text-white"> <font-awesome-icon :icon="['fas', 'book']" /></span>
        </a>
        <b-modal id='loan-form' size="xl">
            <template #modal-title>
                <p class="akkurate-green fw-bold">Loan Application Form</p>
            </template>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <statement-institution :institution="institution" :current_date="false"></statement-institution>
                    </div>
                    <div class="col-12 fs-6 text-center mb-3 fw-bold text-decoration-underline">
                        LOAN APPLICATION FORM
                    </div>
                    <div class="col-12 text-center mb-3">
                        <img class="img-fluid profile_pic_size" :src="loan.account.customer.passport_pic_path">
                    </div>
                    <hr />
                    <div class="col-4 mb-3">
                        <div class="row akkurate-small">
                            <div class="col-6">
                                Date Appiled
                            </div>
                            <div class="col-6 fw-bold">
                                {{ loan.entry_date }}
                            </div>
                            <div class="col-6">
                                Loan Processing Date
                            </div>
                            <div class="col-6 fw-bold">
                                {{ loan.start_date }}
                            </div>
                            <div class="col-6">
                                Code
                            </div>
                            <div class="col-6 fw-bold">
                                {{ loan.account.customer.code }}
                            </div>
                            <div class="col-6">
                                Staff No
                            </div>
                            <div class="col-6">

                            </div>
                            <div class="col-6">
                                Loan No
                            </div>
                            <div class="col-6 fw-bold">
                                {{ loan.loan_number }}
                            </div>
                        </div>
                    </div>
                    <hr class="mb-0" />
                    <div class="col-12 akkurate-green  px-2">
                        PERSONAL INFO
                    </div>
                    <hr />
                    <div class="row mb-3">
                        <div class="col-12 d-flex">
                            <div>Account Name</div>
                            <div class="dashed-border text-center">{{ getFullName }}</div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-6 d-flex">
                            <div>Gender</div>
                            <div class="dashed-border text-center">{{ loan.account.customer.gender }}</div>
                        </div>
                        <div class="col-6 d-flex">
                            <div>Date of Birth</div>
                            <div class="dashed-border text-center">{{ loan.account.customer.date_of_birth }}</div>
                        </div>

                    </div>
                    <div class="row mb-3">
                        <div class="col-6 d-flex">
                            <div>Personal Address</div>
                            <div class="dashed-border text-center">{{ loan.account.customer.postal_address }}</div>
                        </div>
                        <div class="col-6 d-flex">
                            <div>Hse No./ Digital Address</div>
                            <div class="dashed-border text-center">{{ loan.account.customer.digital_address }}</div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-12 d-flex">
                            <div>Occupation</div>
                            <div class="dashed-border text-center">{{ loan.account.customer.occupation }}</div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-12 d-flex">
                            <div>Employer Address</div>
                            <div class="dashed-border text-center">{{ loan.account.customer.employer_address }}</div>
                        </div>
                    </div>

                    <hr class="mb-0 mt-2" />
                    <div class="col-12 akkurate-green px-2">
                        LOAN TYPE
                    </div>
                    <hr />

                    <div class="row">
                        <div class="col-4 text-decoration-underline text-center">
                            SHARE BALANCE
                        </div>
                        <div class="col-4 text-decoration-underline text-center">
                            SAVING BALANCE
                        </div>
                        <div class="col-4 text-decoration-underline text-center">
                            LOAN BALANCE
                        </div>
                    </div>

                    <hr class="mb-0 mt-2" />
                    <div class="col-12 akkurate-green px-2">
                        LOAN PURPOSE
                    </div>
                    <hr />

                    <div class="row mb-3">
                        <div class="col-12 d-flex">
                            <div>Explain the purpose</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-12 d-flex">
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 text-center">
                            BORROWER BENEFICIARIES
                        </div>
                    </div>

                    <div class="row mt-2">
                        <b-table-simple bordered>
                            <b-thead>
                                <b-tr>
                                    <b-th>NAME</b-th>
                                    <b-th>RELATIONSHIP</b-th>
                                    <b-th>DATE OF BIRTH</b-th>
                                    <b-th>MOBILE NUMBER</b-th>
                                </b-tr>
                            </b-thead>
                        </b-table-simple>
                    </div>


                    <hr class="mb-0 mt-2" />
                    <div class="col-12 akkurate-green px-2">
                        GUARANTORS
                    </div>
                    <hr />
                    <p>GUARANTERS DECLARATION: I / We declare that in case the borrower default of the said loan my /
                        our Savings
                        and Shares balance and personal properties should be used to defray the loan and interest.</p>

                    <div class="col-12">
                        <b-table-simple bordered>
                            <b-thead>
                                <b-tr>
                                    <b-th>NAME</b-th>
                                    <b-th>PB NO.</b-th>
                                    <b-th>AMOUNT GUARANTEED</b-th>
                                    <b-th width="50%">AMOUNT IN WORDS</b-th>
                                    <b-th>SIGN</b-th>
                                    <b-th>MOBILE NO.</b-th>
                                </b-tr>
                            </b-thead>
                        </b-table-simple>
                    </div>
                    <div class="col-12 akkurate-small text-center mt-3">
                        NEXT OF KIN NOTE <span class="fst-italic">(Next of kin must be above 21 years of age)</span>
                    </div>
                    <div class="col-12">
                        <b-table-simple bordered>
                            <b-thead>
                                <b-tr>
                                    <b-th>NAME</b-th>
                                    <b-th>ADDRESS</b-th>
                                    <b-th>RELATIONSHIP</b-th>
                                    <b-th>AGE</b-th>
                                    <b-th>MOBILE NUMBER</b-th>
                                </b-tr>
                            </b-thead>
                        </b-table-simple>
                    </div>
                    <div class="col-12 akkurate-small text-center mt-3 fst-italic">
                        BANKERS OF BORROWER
                    </div>
                    <div class="col-12">
                        <b-table-simple bordered>
                            <b-thead>
                                <b-tr>
                                    <b-th>NAME OF BANKER</b-th>
                                    <b-th>BANK ACCOUNT NUMBER</b-th>
                                    <b-th>BANK BRANCH</b-th>
                                    <b-th>REGION</b-th>
                                </b-tr>
                            </b-thead>
                        </b-table-simple>
                    </div>

                    <div class="row mb-3">
                        <div class="col-12 d-flex">
                            <div>RELIGION/OTHER</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 d-flex">
                            <div>NAME OF RELIGION</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 d-flex">
                            <div>LOCATION OF WORSHIP</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-4 d-flex">
                            <div>BORROWERS SIGNATURE</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                        <div class="col-4 d-flex">
                            <div>PHONE</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                        <div class="col-4 d-flex">
                            <div>DATE</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-8 d-flex">
                            <div>BORROWERS NAME</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                        <div class="col-4 d-flex">
                            <div>PASSBOOK NO.</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="text-decoration-underline">OFFICE USE ONLY</div>
                        <div class="akkurate-small">GUARANTORS OFFICE INFORMATION DETAILS</div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-12">
                            <b-table-simple bordered>
                                <b-thead>
                                    <b-tr>
                                        <b-th class="text-center">P8 NO</b-th>
                                        <b-th class="text-center">FIXED DEPOSIT NO</b-th>
                                        <b-th class="text-center">FIXED DEPOSIT</b-th>
                                        <b-th class="text-center">SHARE BALANCE</b-th>
                                        <b-th class="text-center">SAVING BALANCE</b-th>
                                        <b-th class="text-center">SHARE/SAV. BALANCE</b-th>
                                        <b-th class="text-center">LESS LOAN BAL.</b-th>
                                        <b-th class="text-center">TOTAL NET BAL.</b-th>
                                        <b-th class="text-center">AMOUNT GUARANTEED</b-th>
                                        <b-th class="text-center">SAVING/<br />SHARES</b-th>
                                        <b-th class="text-center">+ POSITIVE<br /> - NEGATIVE</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                    </div>
                    <hr class="mb-0" />
                    <div class="col-12 akkurate-green  px-2">
                        DECLARATION
                    </div>
                    <hr />
                    <div class="row mb-2">
                        <div class="col-6 d-flex">
                            <div> I/We</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                        <div class="col-6 d-flex">
                            <div>
                                the undersigned wish to borrow an amount of, (In Words)
                            </div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6 d-flex">
                            <div class="dashed-border text-center"></div>
                        </div>
                        <div class="col-6 d-flex">
                            <div>(In Figures)</div>
                            <div class="dashed-border text-center"></div>
                            <div>and agree to pay</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex">
                            <div>back the principal amount and interest</div>
                            <div class="dashed-border text-center"></div>
                            <div>for the number of month installment(s).</div>
                        </div>
                    </div>
                    <div class="col-12 mb-4 mt-4">
                        I/We further declare that the above information is true to the best of my/our knowledge and
                        agree to abide by the by-laws of the society, the loan policy any variation set by the loans
                        committee.
                    </div>
                    <div class="row mb-3">
                        <div class="col-6">
                            Signature/Thumbprint......................................
                        </div>
                        <div class="col-6 text-end">
                            Date......................................................
                        </div>
                    </div>


                    <hr class="mb-0" />
                    <div class="col-12 akkurate-green  px-2">
                        FOR OFFICIAL USE ONLY
                    </div>
                    <hr />
                    <div class="row mb-4">
                        <div class="col-12 d-flex">
                            <div>LOAN OFFICER’S APPRAISAL</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12">
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12">
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12 d-flex">
                            <div>MANAGER’S APPRAISAL</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12">
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12">
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>

                    <hr class="mb-0" />
                    <div class="col-12 akkurate-green  px-2">
                        LOANS COMMITTEE ACTION
                    </div>
                    <hr />

                    <div class="row mb-3">
                        <div class="col-12 d-flex">
                            <div>1. Application Approved/Rejected</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 d-flex">
                            <div>If rejected. give reasons</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 d-flex">
                            <div>2. Amount Approved (In words)</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 d-flex">
                            <div>In Figures GHc</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>

                    <hr class="mb-0" />
                    <div class="col-12 akkurate-green px-2">
                        LOANS COMMITTE APPROVAL
                    </div>
                    <hr />

                    <div class="row mb-4 mt-3">
                        <div class="col-4 text-center">
                            <div class="dashed-border text-center"></div>
                            <div>Chairman</div>
                        </div>
                        <div class="col-4 text-center">
                            <div class="dashed-border text-center"></div>
                            <div>Signature</div>
                        </div>
                        <div class="col-4 text-center">
                            <div class="dashed-border text-center"></div>
                            <div>Date</div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-4 text-center">
                            <div class="dashed-border text-center"></div>
                            <div>Secretary</div>
                        </div>
                        <div class="col-4 text-center">
                            <div class="dashed-border text-center"></div>
                            <div>Signature</div>
                        </div>
                        <div class="col-4 text-center">
                            <div class="dashed-border text-center"></div>
                            <div>Date</div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-4 text-center">
                            <div class="dashed-border text-center"></div>
                            <div>Member</div>
                        </div>
                        <div class="col-4 text-center">
                            <div class="dashed-border text-center"></div>
                            <div>Signature</div>
                        </div>
                        <div class="col-4 text-center">
                            <div class="dashed-border text-center"></div>
                            <div>Date</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <statement-institution :title="institution.name" :institution="institution" :current_date="false"></statement-institution>
                    </div>

                    <div class="row mb-3">
                        <div class="col-12 text-end">
                            SHORT APPLICATION FORM 1
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-12 text-decoration-underline">
                            LOAN POLICY COVER APPLICATION (HEALTH DECLARATION) FORM
                        </div>
                        <div class="col-12 akkurate-small text-decoration-underline">
                            (THE LOAN PROTECTION PLAN (LPP) PROVIDES DEATH AND DISABILITY BENEFITS IN THE EVENT OF
                            INSURED’S DEATH
                            OR DISABILITY RESPECTIVELY)
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-8 d-flex">
                            <div>Account Name</div>
                            <div class="dashed-border text-center"></div>

                        </div>
                        <div class="col-4 d-flex">
                            <div>Code</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4 d-flex">
                            <div>Phone No</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                        <div class="col-4 d-flex">
                            <div>DOB</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                        <div class="col-4 d-flex">
                            <div>Age</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-8 d-flex">
                            <div>Occupation</div>
                            <div class="dashed-border text-center"></div>

                        </div>
                        <div class="col-4 d-flex">
                            <div>Code</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4 d-flex">
                            <div>Beneficiary</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                        <div class="col-4 d-flex">
                            <div>Relationship</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                        <div class="col-4 d-flex">
                            <div>Age</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-8 d-flex">
                            <div>Address of Beneficiary</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                        <div class="col-4 d-flex">
                            <div>Tel. #</div>
                            <div class="dashed-border text-center"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-10">
                            Please, at present do you confirm that you are in good health and actively performing the
                            usual duties
                            of your occupation?
                        </div>
                        <div class="col">
                            <b-form-checkbox v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="Yes">
                                Yes
                            </b-form-checkbox>

                        </div>
                        <div class="col">
                            <b-form-checkbox v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="No">
                                No
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10">
                            At present are you aware of or have you received advice from your doctor that you are
                            suﬀering from any
                            illness? If yes, please specify (for quality amount above GHC1O,OOO.OO)
                        </div>
                        <div class="col">
                            <b-form-checkbox v-model="selected_2" :aria-describedby="ariaDescribedby" name="options_2" value="Yes">
                                Yes
                            </b-form-checkbox>

                        </div>
                        <div class="col">
                            <b-form-checkbox v-model="selected_2" :aria-describedby="ariaDescribedby" name="options_2" value="No">
                                No
                            </b-form-checkbox>
                        </div>
                    </div>

                    <hr />
                    <div class="row mb-5">
                        <div class="col-12 mb-2">
                            NOTE: If QUESTION 2 IS ANSWERED 'YES' THEN THE LONG APPLICATION FORM (2) MUST BE COMPLETED
                            AND SUBMITTED TO CUA LTD.; IF ONLY THE AMOUNT IN
                            FORCE EXCEEDS GH$1O,OOO.OO IN SUCH
                            A CASE COVERAGE WILL NOT TAKE EFFECT UNTIL APPLICATION IS APPROVED BY CUA LTD.
                        </div>
                        <div class="col-12 mb-2">
                            I declare that to the best of my knowledge I am in good health and am able to perform
                            the normal activities in the pursuit of my livelihood.
                        </div>
                        <div class="col-12 mb-2">
                            I declare that the above answers are true and complete and have been given by me
                            and I do hereby agree that they shall form the basis of my proposed coverage.
                        </div>
                        <div class="col-12 mb-2">
                            I further agree that CUA Ltd. shall not be liable for any claim on account of any illness,
                            injury or death the cause of which was known prior to application for coverage but was
                            withheld or concealed in the above statement.
                        </div>
                        <div class="col-12 mb-2">
                            Herewith, I also give consent and authorisation to CUA Ltd. to seek any information
                            from any doctor who has ever attended to me and from any life assurance oﬃce to
                            which a proposal on my life was made.
                        </div>
                        <div class="col-12 mb-2">
                            I understand that disqualification from coverage will entitle me only for refund of premiums.
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="dashed-border"></div>
                            <div class="text-center">APPLICANT’S SIGNATURE</div>
                        </div>
                        <div class="col-6 text-end">
                            <div class="dashed-border"></div>
                            <div class="text-center">DATE</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="d-flex">
                                <div>WITNESS</div>
                                <div class="dashed-border"></div>
                            </div>
                            <div class="text-center">LOAN OFFICER/OFFICE MANAGER</div>
                        </div>
                        <div class="col-6 text-end mt-auto">
                            <div class="dashed-border"></div>
                            <div class="text-center">DATE</div>
                        </div>
                    </div>



                </div>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                    class="float-left"
                    v-if="isLoading"
                    :isLoading="isLoading"
                    ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="$bvModal.hide('loan-form')" type="submit"
                        class="akkurate-grey me-2 btn-size  float-right">Close</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";

export default {
    props: ['institution', 'loan'],
    components: {
        Multiselect,
        StatementInstitution
    },
    data() {
        return {
            form: {
                date_applied: "",
                loan_process_date: "",
                cust_code: "",
                staff_num: "",
                loan_num: "",
                account_name: "",
                gender_value: "",
                date_of_birth: "",
                personal_address: "",
                digital_address: "",
                occupation: "",
                employer_address: "",
                errors: []
            },
            gender_options: ['Male', 'Female'],
            selected:'',
            selected_2:''
        }

    },
    methods: {

    },
    computed: {
        getFullName() {
            const first_name = this.loan.account.customer.first_name != undefined ? this.loan.account.customer.first_name : ""
            const middle_name = this.loan.account.customer.middlename != undefined ? this.loan.account.customer.middlename : ""
            const last_name = this.loan.account.customer.last_name != undefined ? this.loan.account.customer.last_name : ""
            return first_name + ' ' + middle_name + ' ' + last_name
        }
    }
}
</script>

<style scoped>
.dashed-border {
    flex: 1;
    border-bottom: 2px dashed #2b2a2a;
    /* Adjust the thickness and color as needed */
}
</style>