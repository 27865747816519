<template>
  <div id="wrapper">
    <SidebarView :members="true" :add-member="true" :member-visible="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <form @submit.prevent="submitMember()" :add_or_edit="add_or_edit">
          <div class="container-fluid">
            <div class="row pb-2">
              <div class="col-md-12">
                <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size mb-2">
                  Save
                </button>
              </div>
            </div>
          <div class="general-error d-flex justify-content-center pt-2 pb-2">
                <errors v-if="form.message">
                  {{ form.message }}
                </errors>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div
                    class="card-header py-3 d-flex flex-row align-items-center"
                  >
                    <h6 class="m-0 fw-bold text-green">Personal Details</h6>
                  </div>
                  <div class="card-body">
                    <personal-details :form="form" @responseFront="handleResponseFront" @responseBack="handleResponseBack" @responsePassportPic="responsePassportPic" @responseSignaturePic="responseSignaturePic" :add_or_edit="add_or_edit"></personal-details>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div
                    class="card-header py-3 d-flex flex-row align-items-center"
                  >
                    <h6 class="m-0 fw-bold text-green">Employment Details</h6>
                  </div>
                  <div class="card-body">
                    <employer-details :form="form" :add_or_edit="add_or_edit"></employer-details>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div
                    class="card-header py-3 d-flex flex-row align-items-center"
                  >
                    <h6 class="m-0 fw-bold text-green">Financial Information</h6>
                  </div>
                  <div class="card-body">
                    <financial-information :form="form" :add_or_edit="add_or_edit"></financial-information>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 mt-5 mt-md-0 col-md-12">
                <div class="card card-shape home-box">
                  <div
                    class="card-header py-3 d-flex flex-row align-items-center"
                  >
                    <h6 class="m-0 fw-bold text-green">Address</h6>
                  </div>
                  <div class="card-body">
                    <address-view
                      :form="form"
                      :region_options="region_options"
                      :branch_options="branch_options"
                    ></address-view>
                  </div>
                  <hr />
                  <div class="text-center py-3">
                    <!-- <button
                      type="submit"
                      class="btn akkurate-green-btn text-white btn-size my-2 me-2"
                    >
                      Save
                    </button>
                    <button
                      type="reset"
                      class="btn akkurate-gray-btn text-white btn-size my-2 me-2"
                    >
                      Reset
                    </button> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div
                    class="card-header py-3 d-flex flex-row align-items-center"
                  >
                    <h6 class="m-0 fw-bold text-green">Spouse Details</h6>
                  </div>
                  <div class="card-body">
                    <spouse-details :form="form" @responseFront="handleResponseFront" @responseBack="handleResponseBack" @responsePassportPic="responsePassportPic" @responseSignaturePic="responseSignaturePic" :add_or_edit="add_or_edit"></spouse-details>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="py-3 d-flex flex-row align-items-center justify-content-between">
                <!-- <b-button  size="sm" class="fw-semibold akkurate-green-btn" type="button" href="">
                  <i class="fas fa-plus"></i> Add Acounts
                </b-button> -->
                <AddAccount
                  :account_types="account_types"
                  @addAccount="addAccount"
                >
                </AddAccount>
              </div>
              <div class="err-accounts">
                <errors v-if="form.errors.accounts">
                  {{ form.errors.accounts[0] }}
                </errors>
              </div>
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div
                    class="card-header py-3 d-flex flex-row align-items-center"
                  >
                    <h6 class="m-0 fw-bold text-green">Accounts</h6>
                  </div>
                  <div class="card-body">
                    <vue-good-table :columns="columns" :rows="form.accounts" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div
                    class="card-header py-3 d-flex flex-row align-items-center" style="display: flex;justify-content: space-between;"
                  >
                    <div class="">
                      <h6 class="m-0 fw-bold text-green">Reference Details</h6>
                    </div>
                    <div class="">
                      <span><h6>In case of an emergency</h6></span>
                    </div>
                  </div>
                  <div class="card-body">
                    <emergency-contact-details :form="form" :add_or_edit="add_or_edit"></emergency-contact-details>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div
                class="py-3 d-flex flex-row align-items-center justify-content-between"
              >
                <NextOfKin @addNextOfKin = "addBeneficiary"/>
              </div>
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div
                    class="card-header py-3 d-flex flex-row align-items-center"
                  >
                    <h6 class="m-0 fw-bold text-green">Next of kin</h6>
                  </div>
                  <div class="card-body">
                    <vue-good-table :columns="kin_columns" :rows="form.beneficiaries" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row pt-4">
              <div class="col-md-12">
                <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size mb-2">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <footer></footer>
    </div>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import Footer from "@/views/main/components/Footer.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import AddressView from "../forms/Address";
import PersonalDetails from "../forms/PersonalDetails";
import SpouseDetails from "../forms/SpouseDetails";
import EmployerDetails from "../forms/EmployerDetails";
import EmergencyContactDetails from "../forms/EmergencyContactDetails";
import FinancialInformation from "../forms/FinancialInformation";
import AddAccount from "../forms/Modal/AddAccount";
import NextOfKin from "../forms/Modal/NextOfKin.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "CreateMember",
  mounted() {
    this.loadPageOptions();
  },
  components: {
    SidebarView,
    Footer,
    TopbarView,
    AddressView,
    PersonalDetails,
    AddAccount,
    NextOfKin,
    EmployerDetails,
    FinancialInformation,
    SpouseDetails,
    EmergencyContactDetails
  },
  data() {
    return {
      ...this.initiateState(),
      front_card_path: "",
      front_card_name: "",
      back_card_path: "",
      back_card_name: "",
      region_options: [],
      branch_options: [],
      isSaving:false,
      // uploads: {
      //   front_ghana_card: null,
      //   back_ghana_card: null,
      //   signature: null,
      // },

      // regions: [],
      // branches: [],
      account_types: [],
      add_or_edit: 'add',
      columns: [

        {
          label: "Account Type",
          field: "account_type.name",
        },
        {
          label: "Balance",
          field: "account_balance",
        },
        {
          label: "Status",
          field: "status",
        },
      ],
      //rows: [
      //{ acc_id: 1, account_type: "John", acc_num:"11111111", account_balance: 20, created_at: '2011-10-31' },
      //],

      kin_columns: [
        {
          label: "Surname",
          field: "surname",
        },
        {
          label: "Other name(s)",
          field: "othername",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Date of birth",
          field: "dob",
        },
        {
          label: "Phone Number",
          field: "phone",
        },
        {
          label: "Percentage Shares",
          field: "percentage_share",
        },
        {
          label: "Relation",
          field: "relation",
        },
        {
          label: "Address",
          field: "address",
        },
      ],
      kin_rows: [],
    };
  },

  methods: {
    loadPageOptions() {
      this.$Progress.start();
      this.isLoading = true;
      ApiService.get("/customers/dropdown")
        .then((response) => {
          console.log(response.data.data);
          this.account_types = response.data.account_types;
          this.region_options = response.data.regions;
          this.branch_options = response.data.branches;
          this.isLoading = false;
          // $emit('coa-head-saved', response.data)
          this.$Progress.finish();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.fail();
        });
    },
    addAccount(acc) {
      var account = {};
      account.account_type = acc.account_type;
      account.account_balance = acc.account_balance;
      account.status = acc.status;
      this.form.accounts.push(account);

      // { acc_id: 1, account_type: "John", acc_num:"11111111", account_balance: 20, created_at: '2011-10-31' }
    },
    addBeneficiary(nok) {
      var nextofkin = {};
      nextofkin.surname = nok.surname;
      nextofkin.othername = nok.othername;
      nextofkin.email = nok.email;
      nextofkin.dob = nok.dob;
      nextofkin.phone = nok.phone;
      nextofkin.relation = nok.relation;
      nextofkin.address = nok.address;
      nextofkin.percentage_share = nok.percentage_share
      console.log(nextofkin);
      this.form.beneficiaries.push(nextofkin);
    },
    handleResponseFront(message) {
      // console.log(message);
      this.front_card_path = message.front_ghana_path;
      this.front_card_name = message.front_ghana_name;
    },
    handleResponseBack(message) {
      // console.log(message);
      this.back_card_path = message.back_ghana_path;
      this.back_card_name = message.back_ghana_name;
    },
    responsePassportPic(obj) {
      // console.log(obj);
      this.form.passport_pic_path = obj.passport_pic_path;
      this.form.passport_pic_name = obj.passport_pic_name;
    },
    responseSignaturePic(obj) {
      // console.log(obj);
      this.form.signature_image_path = obj.signature_image_path;
      this.form.signature_image_name = obj.signature_image_name;
    },
    submitMember() {
      this.form.message = "";
      this.form.errors = [];
      this.form.gh_card_front_path = this.front_card_path;
      this.form.gh_card_front_name = this.front_card_name;
      this.form.gh_card_back_path = this.back_card_path;
      this.form.gh_card_back_name = this.back_card_name;
      this.isSaving = true;
      this.$Progress.start();
      console.log(this.form);
      ApiService.post("/customers/store", this.form).then((response) => {
        // console.log(response);
        this.isSaving = false;
        this.$Progress.finish();
        toast.fire({
          icon: "success",
          title: response.data.message,
        });
        this.isSaving = false;
        this.form = this.initiateState().form;
        setTimeout(() => {
          window.location.reload();
        }, 1000);

      }).catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          // console.log(error);
          if (error.response.data.errors) {
            // console.log(error.response.data.errors);
            this.form.errors = error.response.data.errors;
          }

          //error message
          if (error.response.data.message) {
            this.form.message = error.response.data.message;
          }
          console.log(error.response.data)
        });
    },
    initiateState() {
      return {
        form: {
          accounts: [],
          beneficiaries: [],
          first_name: "",
          middlename: "",
          last_name: "",
          title: "",
          email: "",
          mobile: "",
          unapproved_withdrawal_max_amount:"",
          gender: "",
          date_of_birth: "",
          marital_status: "",
          address: "",
          digital_address: "", //not required
          home_town: "", //not required 
          landmark: "",
          region: "",
          branch: "",
          external_code: "",
          staff_id: "",
          employer_name: null, //not required
          ghana_card_number: null, //not required
          status: "Pending",
          customer_type: "",
          // region_options: [],
          // branch_options: [],
          errors: [],
          message: "",
          gh_card_front_path: "",
          gh_card_front_name: "",
          gh_card_back_name: "",
          gh_card_back_path: "",

          employer_address: null,
          employer_mobile: null,
          occupation: null,
          employee_position: null,
          source_of_funds: null,
          bankers_name: null,
          bankers_address: null,
          bankers_location: null,
          monthly_salary: null,
          signature_image_path: null,
          signature_image_name: null,
          passport_pic_path: null,
          passport_pic_name: null,
          note: null,
          mandate: null,
          bank_account_number: null,
          nearest_landmark: null,
          spouse_name: null,
          spouse_mobile: null,
          spouse_email: null,
          spouse_address: null,
          mobile_secondary: null,
          employment_status: null,
          reference_name: null,
          reference_mobile: null,
          reference_email: null,
          reference_address: null,

        },

      }
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>