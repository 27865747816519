var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wrapper"}},[_c('SidebarView',{attrs:{"users":true,"user-visible":true,"all-users":true}}),_c('div',{staticClass:"d-flex flex-column",attrs:{"id":"content-wrapper"}},[_c('div',{attrs:{"id":"content"}},[_c('TopbarView'),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',[_c('div',{staticClass:"py-3 d-flex flex-row align-items-center justify-content-between"},[_c('b-button',{staticClass:"fw-semibold akkurate-green-btn",attrs:{"size":"sm","type":"button","href":"/users/create"}},[_c('span',{staticClass:"akkurate-small"},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Add User")])]),_c('b-link',{staticClass:"fw-semibold float-end me-3 akkurate-green",on:{"click":_vm.exportToExcel}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'book']}})],1)],1),_c('div',{staticClass:"card card-shape home-box"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{ref:"userTable",attrs:{"styleClass":"vgt-table bordered striped","mode":"remote","columns":_vm.columns,"rows":_vm.users,"line-numbers":true,"isLoading":_vm.isLoading,"totalRows":_vm.totalRecords,"search-options":{
                  enabled: true,
                  placeholder: 'Search by first name, last name, username, email, old account number',
                  trigger: 'enter'

                },"sort-options":{
                  enabled: false
                },"pagination-options":{
                  enabled: true,
                  perPage: _vm.serverParams.perPage,
                }},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-search":_vm.onSearch,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',{staticClass:"d-flex"},[_c('users-view',{attrs:{"data":props.row}}),_c('a',{staticClass:"btn btn-sm akkurate-primary border-0 text-white",attrs:{"title":"Edit","href":`/users/${props.row.id}/edit`}},[_c('span',{staticClass:"akkurate-small"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'edit']}})],1)]),_c('button',{staticClass:"btn btn-sm akkurate-teal border-0 text-white",attrs:{"title":"Reset Password"},on:{"click":function($event){return _vm.resetPassword(props.row)}}},[_c('span',{staticClass:"akkurate-small"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'key']}})],1)]),_c('a',{staticClass:"btn btn-sm akkurate-danger-btn border-0 text-white",attrs:{"title":"Delete"},on:{"click":function($event){return _vm.confirmDeleteUser(props.row)}}},[_c('span',{staticClass:"akkurate-small"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash']}})],1)])],1):(props.column.field == 'roles')?_c('span',[_c('div',[_vm._v(_vm._s(props.row.roles['0'].display_name))])]):_vm._e()]}}])})],1)])])])])])],1),_c('Footer')],1),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center"},[_c('h6',{staticClass:"m-0 fw-bold text-green"},[_vm._v("All Users")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-inline scroll-to-top",attrs:{"href":"#page-top"}},[_c('i',{staticClass:"fas fa-angle-up"})])
}]

export { render, staticRenderFns }