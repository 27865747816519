UsersIndex

<template>
<div id="wrapper">
        <SidebarView :members="true" :all-member="true" :member-visible="true"/>
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container-fluid">
                    
             <!-- begin row  -->
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <member-filter :form="form" :filterTable="filterTable"></member-filter>
                </div>

              <div class="card card-shape home-box">
                <div class="card-header py-3">
                  <div class="row">
                    <div class="col">
                      <h6 class="m-0 fw-bold text-green">All Members</h6>
                    </div>
                    <div class="col text-end">
                      <b-button class="btn btn-sm akkurate-green-btn" href="/members/create" variant="primary">
                          <span class="akkurate-small"> <i class="fas fa-plus"></i> Add Member</span>
                     </b-button>
                     <b-link @click="exportToExcel" class="fw-semibold ms-2 akkurate-green"> <font-awesome-icon :icon="['fas', 'file-excel']" /></b-link> 
                    </div>
                  </div>
                </div>
                <div class="card-body">
                <vue-good-table
                styleClass="vgt-table bordered table-dropdown striped striped"
                mode="remote"
                ref="memberTable"
                :columns="columns"
                :rows="members"
                :isLoading.sync="isLoading"
                :totalRows="totalRecords"
              
                :sort-options="{
                  enabled: false,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: serverParams.perPage,
                }"
                
                  @on-search="onSearch"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  
                  
                >
                <!-- Add your custom delete button column -->
                <template slot="table-row" slot-scope="props">
                  <span class="d-flex" v-if="props.column.field == 'action'">
                      <!-- <button class="btn btn-sm me-2 akkurate-ash text-white" @click="viewData(props.row)">
                        <font-awesome-icon :icon="['fas', 'money-bill']" /> <span>transaction</span>
                      </button> -->

                      <!-- <div class="dropdown no-arrow d-inline">
                          <a title="Transaction" class="btn btn-sm akkurate-ash text-white dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'money-bill']" /></span>
                          </a>
                          <div class="dropdown-menu shadow animated--fade-in"
                              aria-labelledby="dropdownMenu">
                              <div class="dropdown-header">Transaction</div>
                              <a class="dropdown-item" href="#">Deposit</a>
                              <a class="dropdown-item" href="#">Withdrawal</a>
                          </div>
                      </div> -->
                      <a title="View" class="btn btn-sm akkurate-warning text-white broder-0" @click="showCustomerViewModal(props.row.id)">
                        <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
                      </a>
                      <a title="Edit" class="btn btn-sm akkurate-primary text-white border-0"  :href="`/members/${props.row.id}/edit`">
                      <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'edit']" /></span> 
                      </a>
                      <b-button  title="Delete" class="btn btn-sm akkurate-red-btn text-end" @click="deleteMember(props.row)" variant="primary">
                          <span class="akkurate-small"> <i class="fas fa-trash"></i></span>
                     </b-button>

                      <div class="dropdown no-arrow d-inline">
                          <a title="Status" class="btn btn-sm akkurate-green-btn text-white dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'user']" /></span>
                          </a>
                          <div class="dropdown-menu shadow animated--fade-in"
                              aria-labelledby="dropdownMenu">
                              <div class="dropdown-header">Status</div>
                              <b-button v-if="props.row.status != 'Active'" class="dropdown-item" @click="setUserStatus('Active', props.row)"><span class="small">Active</span></b-button>
                              <b-button v-if="props.row.status != 'Inactive'" class="dropdown-item" @click="setUserStatus('Inactive', props.row)"><span class="small">Inactive</span></b-button>
                              <b-button v-if="props.row.status != 'Dormant'" class="dropdown-item" @click="setUserStatus('Dormant', props.row)"><span class="small">Dormant</span></b-button>
                          </div>
                      </div>

                      <!-- <a v-if="props.row.status == 'Inactive'" title="Activate" class="btn btn-sm akkurate-green-btn text-white broder-0" @click="setUserStatus(props.row)">
                        <span class="akkurate-small"><font-awesome-icon  :icon="['fas', 'check']" /></span>
                      </a>
                      <a v-if="props.row.status == 'Active'" title="Deactivate" class="btn btn-sm akkurate-danger-btn text-white broder-0" @click="setUserStatus(props.row)">
                        <span class="akkurate-small"><font-awesome-icon  :icon="['fas', 'close']" /></span>
                      </a> -->
                  </span>
                  <span class="d-flex justify-content-center" v-else-if="props.column.field == 'status'">
                    <span class="badge text-bg-success rounded-pill" v-if="props.row.status == 'Active'"><font-awesome-icon :icon="['fas', 'check']" /> {{ props.row.status }}</span>
                    <span class="badge text-bg-danger rounded-pill" v-else-if="props.row.status == 'Inactive'"><font-awesome-icon :icon="['fas', 'close']" /> {{ props.row.status }}</span>
                    <span class="badge badge-default rounded-pill" v-else-if="props.row.status == 'Dormant'">{{ props.row.status }}</span>
                    <span class="badge text-bg-warning rounded-pill" v-else>{{ props.row.status }}</span>

                  </span>
                  <span v-else-if="props.column.field == 'fullname'">
                    <span>{{ props.row.first_name }} {{ props.row.last_name }}</span>
                  </span>
                </template>
                </vue-good-table>
                </div>
              </div>
              </div>
            </div>
            <!-- end row  -->

            <b-modal size="xl" id="bv-modal-customer-view" hide-footer>
                <member-view :id="member_id"></member-view>
            </b-modal>
           
            </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
</div>

</template>

<script>

// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

import ApiService from "@/core/services/api.service";

import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';


import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import MemberFilter from "../forms/MemberFilter.vue";
import MemberView from '@/views/main/pages/member/view/MemberView.vue'
// import $ from 'jquery';
// import 'datatables.net';
// import config from '@/config.js'
// import JwtService from "@/core/services/jwt.service";

export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    MemberFilter,
    MemberView,
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
  },

  data() {
    return {
      member_id: "",
      totalRecords: 0,
      searchFilter: "",
      isLoading: true,
      form: {
        status: "",
        search_term: "",
        from_date: "",
        to_date: "",
      },

      columns: [
        {
          label: 'Cust. Code',
          field: 'code',
          type: 'text',
        },
        {
          label: 'Old Code',
          field: 'external_code',
          type: 'text',
        },
        {
          label: 'Fullname',
          field: 'fullname',
          type: 'text',
        },
        // {
        //   label: 'Middle Name',
        //   field: 'middlename',
        //   type: 'text',
        // },
        // {
        //   label: 'Surname',
        //   field: 'last_name',
        //   type: 'text',
        // },
        {
          label: 'Email',
          field: 'email',
          type: 'email',
        },
        {
          label: 'Mobile',
          field: 'mobile',
          type: 'number',
        },
        // {
        //   label: 'Gender',
        //   field: 'gender',
        // },
        {
          label: 'Source',
          field: 'source',
        },
        {
          label: 'Digital Address',
          field: 'digital_address',
        },
        {
          label: 'Ghana Card',
          field: 'ghana_card_number',
        },
        {
          label: 'Reg. Date',
          field: 'registered_date',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',

          html: true,
          //   formatFn: this.renderActions,
        },
      ],
      members: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: [
          {
            field: '', // example: 'name'
            type: '' // 'asc' or 'desc'
          }
        ],
        page: 1, // what page I want to show
        perPage: 20 // how many items I'm showing per page
      },
    };
  },
  // async created() {
  //   this.loadItems()
  // },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    onSearch(event) {
      // console.log(event)
      this.loadItems(event.searchTerm)
    },
    filterTable() {
      this.loadItems();
    },

    showCustomerViewModal(id) {
      this.member_id = id;
      this.$bvModal.show('bv-modal-customer-view')
    },
    // load items is what brings back the rows from server
    async loadItems(searchFilter = null) {
      this.isLoading = true;
      await ApiService.post("customers/paginate",
        {
          "page": this.serverParams.page,
          "per_page": this.serverParams.perPage,
          "filter": this.form.search_term,
          "branch_id": null,
          "status": this.form.status, //Inactive,	Active, Inactive, Dormant, Closed
          "region_id": null
        })
        .then(response => {
          // console.log(response.data.data);
          this.members = response.data.data;
          // this.per_page = response.data.meta.per_page;
          // console.log(response.data.meta.per_page)
          this.totalRecords = response.data.meta.total;

        }).catch(() => {
          // console.log(error);
        })
        .finally(() => {
          // this.isLoading = false
        });
    },

    confirmDeleteUser(user) {

      swal.fire({
        title: "Disable User",
        text: `Are you sure you want to disable ${user.username}?`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          //   window.location.reload();
        }
      });

    },
    deleteMember(data) {
      swal.fire({
        title: "Please confirm",
        html: `Are you sure you want to delete member <strong>${data.first_name} ${data.last_name}</strong>?<br/>This action cannot be reversed`,
        icon: "warning",
        allowOutsideClick: true,
        allowEscapeKey: true,
        showCancelButton: true, // Show the "Cancel" button
        confirmButtonText: 'Delete', // Customize the text for the "OK" button
        cancelButtonText: 'No', // Text for the custom button
        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
        customClass: {
          confirmButton: 'btn akkurate-red-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
          cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
        }
      }).then(async result => {
        if (result.isConfirmed) {
          this.$Progress.start();
          await ApiService.delete(`/customers/delete/${data.id}`).then((response) => {
            console.log(response);
            this.$Progress.finish();
            swal.fire({
              // title: response.statusText,
              text: response.data.message,
              icon: "success",
              allowOutsideClick: true,
              allowEscapeKey: true,
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: 'okay', // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
              }
            });
            this.loadItems();
          }).catch((error) => {
            this.$Progress.fail();
            // console.log(error);
            swal.fire({
              // title: response.statusText,
              text: error.response.data.message,
              icon: "error",
              allowOutsideClick: true,
              allowEscapeKey: true,
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: 'okay', // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
              }
            });

          })
          //
        }
      });

    },
    setUserStatus(status, data) {
      swal.fire({
        title: "Are you sure?",
        html: `You want to set member status to <strong>${status}</strong> for <strong>${data.first_name}`
          + ` ${data.last_name}</strong>?`,
        icon: "warning",
        allowOutsideClick: true,
        allowEscapeKey: true,
        showCancelButton: true, // Show the "Cancel" button
        confirmButtonText: 'Yes', // Customize the text for the "OK" button
        cancelButtonText: 'No', // Text for the custom button
        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
        customClass: {
          confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
          cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
        }
      }).then(async result => {
        if (result.isConfirmed) {
          this.$Progress.start();
          await ApiService.put(`/customers/status-update/${data.id}`, {
            status: status
          }).then((response) => {
            console.log(response);
            this.$Progress.finish();
            swal.fire({
              title: response.statusText,
              text: response.data.message,
              icon: "success",
              allowOutsideClick: true,
              allowEscapeKey: true,
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: 'okay', // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
              }
            });
            this.loadItems();
          }).catch((error) => {
            this.$Progress.fail();
            console.log(error);
          })
          //
        }
      });
    },

    viewData(data) {
      this.$router.push('/members/view/' + data.id)
      // console.log(id.id);
    },
    exportToExcel() {
      const workbook = XLSX.utils.book_new();

      // Get the table data
      // const tableData = this.$refs.memberTable.filteredRows[0].children;
      // console.log(this.$refs.memberTable.filteredRows[0].children)
      const tableData = this.$refs.memberTable.filteredRows[0].children.map((index) => {
        return {
          member_code:index.code,
          name:`${index.first_name} ${index.middlename} ${index.last_name}`,
          mobile: index.mobile,
          address:index.address,
          date_of_birth:index.date_of_birth,
          registered_date: index.registered_date,
          gender: index.gender,
          branch: index.branch.name ?? "",
        }
      });
      // console.log(tableData);

      // Convert the table data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate the Excel file buffer
      const excelBuffer = XLSX.write(workbook, {
        type: 'array',
        bookType: 'xlsx'
      });

      // Save the Excel file
      FileSaver.saveAs(
        new Blob([excelBuffer], { type: 'application/octet-stream' }),
        'table_data.xlsx'
      );
    },
  }
  // methods: {
  //   getUserOptions() {
  //     this.$Progress.start();
  //     this.isLoading = true;
  //     // axios
  //     //   .get("roles/dropdown")

  //     $(this.$refs.dataTable).DataTable({
  //       serverSide: true,
  //       ajax: {
  //         url: config.api_url + '/users/paginate', // Replace with your server-side endpoint URL
  //         type: 'POST',
  //         beforeSend: function (xhr) {
  //           // Set your authorization header here
  //           xhr.setRequestHeader('Authorization', 'Bearer ' + JwtService.getToken());
  //         },
  //         data: function (data) {

  //           //   this.isLoading = false;
  //           // Customize the request parameters as needed
  //           //   data.page = data.start / data.length + 1;
  //           //   data.pageSize = data.length;
  //           // Add any additional parameters you need, such as sorting information
  //         },
  //         dataSrc: function (json) {
  //           // Handle the server's response here
  //           // Update the DataTables table with the received data
  //           return json.data;
  //         },
  //       },
  //       columns: [
  //         // Define your table columns here
  //         // { data: 'id', title: 'ID' },
  //         { data: 'first_name', title: 'First Name' },
  //         { data: 'surname', title: 'Sur Name' },
  //         { data: 'email', title: 'Email' },
  //         { data: 'username', title: 'Username' },
  //         // Add more columns as needed
  //       ],
  //     });


  //   },
  // }
};
</script>


